import { usePaymentApiClient } from '@apiClients';
import { useMutation } from '@tanstack/react-query';

export const useCreateS3ProjectResource = (projectId: string) => {
  const client = usePaymentApiClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`resources/s3/project/${projectId}`, {
        method: 'post',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
  });
};

export const useCreateImageResource = () => {
  const client = usePaymentApiClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`resources`, {
        method: 'post',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
  });
};

// Similar to hook above, but accepts projectId at
// call time, not when hook is created.
export const useUploadToS3 = () => {
  const client = usePaymentApiClient();

  return useMutation({
    mutationFn: (data: {
      formData: FormData;
      projectId: number;
      isPublicResource?: boolean;
    }) => {
      const { formData, projectId, isPublicResource = false } = data;
      return client(
        `resources/s3/project/${projectId}?public=${isPublicResource}`,
        {
          method: 'post',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
    },
  });
};
