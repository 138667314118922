import {
  Button,
  Col,
  Editor,
  FormLabel,
  HelpPopover,
  HexColorInput,
  ImageUploadButton,
  InfoIcon,
  InputGroup,
  LayoutIcon,
  Row,
  Section,
  SectionTitle,
  Stacked,
  Stretched,
  Text,
} from '@components';
import {
  DEFAULT_EVENT_HEADER_BG_COLOR,
  DEFAULT_EVENT_HEADER_FONT_COLOR,
} from '@constants';
import { LAYOUT_TYPE, LAYOUT_TYPE_NAME } from '@enums';
import {
  validateIdentityEventDetailsFormSchema
} from '@utils';
import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

export const EventPageSetupContent = ({
  wizardState,
  updateWizardState,
  children,
}: {
  wizardState: any;
  updateWizardState: Function;
  editMode?: boolean;
  children?: ReactNode;
}) => {
  let contentBodyTabs = [
    {
      label: 'Preview Page (Registration)',
      value: 'contentBodyUpcoming',
    },
    {
      label: 'Event Page (Live)',
      value: 'contentBodyOpen',
    },
    {
      label: 'Event Page (Closed)',
      value: 'contentBodyClosed',
    },
  ];

  const eventLayout = [
    {
      type: LAYOUT_TYPE.TITLE_ICON_50_50,
      name: LAYOUT_TYPE_NAME.TITLE_ICON_50_50,
    },
    {
      type: LAYOUT_TYPE.TITLE_ICON_50_50_REVERSED,
      name: LAYOUT_TYPE_NAME.TITLE_ICON_50_50_REVERSED,
    },
    {
      type: LAYOUT_TYPE.ICON_100,
      name: LAYOUT_TYPE_NAME.ICON_100,
    },
    {
      type: LAYOUT_TYPE.TITLE_100,
      name: LAYOUT_TYPE_NAME.TITLE_100,
    },
  ];

  const [bodyContentDisplay, setBodyContentDisplay] = useState(
    'contentBodyUpcoming',
  );
  const [formState, setFormState] = useState({
    contentHeaderTitle: wizardState.contentHeaderTitle || '',
    contentHeaderSubtitle: wizardState.contentHeaderSubtitle || '',
    contentHeaderFontColor:
      wizardState.contentHeaderFontColor || DEFAULT_EVENT_HEADER_FONT_COLOR,
    contentHeaderBgColor:
      wizardState.contentHeaderBgColor || DEFAULT_EVENT_HEADER_BG_COLOR,
    bodyTitle: wizardState.bodyTitle || '',
    contentBodyUpcoming: wizardState.contentBodyUpcoming || '',
    contentBodyOpen: wizardState.contentBodyOpen || '',
    contentBodyClosed: wizardState.contentBodyClosed || '',
    contentHeaderBgImage: wizardState.contentHeaderBgImage || '',
    contentHeaderImage: wizardState.contentHeaderImage || '',
    overviewImageUrl: wizardState.overviewImageUrl || '',
    themeLayoutVersion: wizardState.themeLayoutVersion || eventLayout[0].type,
  });

  const validHeaderImage = formState.contentHeaderImage?.trim() !== '';

  const formValid =
    validateIdentityEventDetailsFormSchema(formState) &&
    ((formState.contentHeaderTitle && formState.contentHeaderSubtitle) ||
      validHeaderImage) &&
    formState.themeLayoutVersion &&
    // check if the layout is not title_100 then check if the header image is valid
    (formState.themeLayoutVersion === LAYOUT_TYPE.TITLE_100 ||
      validHeaderImage);

  useEffect(() => {
    updateWizardState({
      ...wizardState,
      ...formState,
      eventPageSetupFormValid: formValid,
      contentBodyTabs,
    });
  }, [formState]);

  const handleChange = (e) =>
    setFormState({ ...formState, [e.target.name]: e.target.value });

  const handleColorChange = (e) => {
    let newColor = '';

    if (e.target.value.length === 0) {
      newColor = '#';
    } else {
      newColor =
        e.target.value[0] === '#' ? e.target.value : `#${e.target.value}`;
    }

    setFormState({ ...formState, [e.target.name]: newColor });
  };

  const handleImageChanged = (imagekey, value) => {
    setFormState({ ...formState, [imagekey]: value });
  };

  const setThemeLayoutVersion = (value) => {
    setFormState({ ...formState, themeLayoutVersion: value });
  };

  return (
    <Stacked gap={5}>
      {/* LAYOUT SELECTION */}
      <Section gap={5}>
        <Col>
          <SectionTitle>1. Select an Event Page Layout</SectionTitle>
          <Text>
            Please pick the general layout for your end-user event setup. This
            is the hero section where you choose your event title, subtitle,
            background, branding and imagery.
          </Text>
        </Col>
        <Col xgap={0} ygap={2.5}>
          <Stretched xgap={4} ygap={0}>
            {eventLayout.map((layout, index) => (
              <LayoutIcon
                value={formState.themeLayoutVersion}
                setValue={setThemeLayoutVersion}
                layout={layout}
                key={index}
              />
            ))}
          </Stretched>
        </Col>
      </Section>
      {/* HEADER SECTION */}
      <Section gap={5}>
        <Col>
          <SectionTitle>2. Choose Your Banner Content</SectionTitle>
        </Col>
        <Col xgap={0} ygap={1.5}>
          <Stretched xgap={4} ygap={0}>
            {/* Event title */}
            <InputGroup
              className='flex-grow'
              label='Event Title'
              placeholder='Enter Here'
              value={formState.contentHeaderTitle}
              name='contentHeaderTitle'
              maxLength={100}
              onChange={handleChange}
              required={true}
            />

            <InputGroup
              className=''
              label='Event Subtitle'
              placeholder='Enter Here'
              value={formState.contentHeaderSubtitle}
              name='contentHeaderSubtitle'
              maxLength={300}
              onChange={handleChange}
              required={true}
            />
          </Stretched>
          <Stretched xgap={4} ygap={0}>
            {/* Font color */}
            <HexColorInput
              label='Font color'
              color={formState.contentHeaderFontColor}
              defaultColor={DEFAULT_EVENT_HEADER_FONT_COLOR}
              onChange={handleColorChange}
              name='contentHeaderFontColor'
              required={true}
              flexPrepend={true}
            />
            {/* Image */}
            {formState.themeLayoutVersion !== LAYOUT_TYPE.TITLE_100 ? (
              <Col className={'w-full'}>
                <Row yalign={'center'} gap={2} className={'mb-4'}>
                  <FormLabel>Logo/Image *</FormLabel>
                  <HelpPopover
                    button={
                      <div className='block cursor-pointer' onClick={() => {}}>
                        <InfoIcon fill={'var(--neutral)'} />
                      </div>
                    }
                  >
                    <div>
                      <Text>
                        Please ensure that the image is in .png format and has a
                        transparent background.
                      </Text>
                      <Text>Max size of 2MB.</Text>
                    </div>
                  </HelpPopover>
                </Row>
                {formState.contentHeaderImage ? (
                  <Row nowrap gap={2} place={'center'} className={'mt-4 w-44'}>
                    <Text>Logo.png</Text>
                    <button
                      onClick={() => {
                        handleImageChanged('contentHeaderImage', '');
                      }}
                    >
                      <AiFillCloseCircle size={14} color='var(--danger)' />
                    </button>
                  </Row>
                ) : (
                  <Row className=''>
                    <ImageUploadButton
                      className={'w-full'}
                      fill={'outline'}
                      onFileUploaded={(result) => {
                        handleImageChanged('contentHeaderImage', result.httpsUrl);
                      }}
                    />
                  </Row>
                )}
              </Col>
            ) : (
              <Col className={'w-full'}></Col>
            )}
          </Stretched>
          <Stretched xgap={4} ygap={0}>
            {/* Background color */}
            <HexColorInput
              label='Background color'
              color={formState.contentHeaderBgColor}
              defaultColor={DEFAULT_EVENT_HEADER_BG_COLOR}
              onChange={handleColorChange}
              name='contentHeaderBgColor'
              required={true}
              flexPrepend={true}
            />

            {/* BG Image */}
            <Col className={'w-full'}>
              <Row yalign={'center'} gap={2} className={'mb-4'}>
                <FormLabel>Background Image (5:1)</FormLabel>
                <HelpPopover
                  button={
                    <div className='block cursor-pointer' onClick={() => {}}>
                      <InfoIcon fill={'var(--neutral)'} />
                    </div>
                  }
                >
                  <div>
                    <Text>Max size of 2MB.</Text>
                  </div>
                </HelpPopover>
              </Row>
              {formState.contentHeaderBgImage ? (
                <Row nowrap gap={2} place={'center'} className={'mt-4 w-44'}>
                  <Text>Background.png</Text>
                  <button
                    onClick={() => {
                      handleImageChanged('contentHeaderBgImage', '');
                    }}
                  >
                    <AiFillCloseCircle size={14} color='var(--danger)' />
                  </button>
                </Row>
              ) : (
                <Row className=''>
                  <ImageUploadButton
                    fill={'outline'}
                    className={'w-full'}
                    onFileUploaded={(result) => {
                      handleImageChanged('contentHeaderBgImage', result.httpsUrl);
                    }}
                  />
                </Row>
              )}
              <Row yalign={'center'} gap={2} className={'mb-4'}>
                <FormLabel>Event Cover Image (7:4)</FormLabel>
                <HelpPopover
                  button={
                    <div className='block cursor-pointer' onClick={() => {}}>
                      <InfoIcon fill={'var(--neutral)'} />
                    </div>
                  }
                >
                  <div>
                    <Text>Max size of 2MB.</Text>
                  </div>
                </HelpPopover>
              </Row>
              {formState.overviewImageUrl ? (
                <Row nowrap gap={2} place={'center'} className={'mt-4 w-44'}>
                  <Text>EventCardCoverImage.png</Text>
                  <button
                    onClick={() => {
                      handleImageChanged('overviewImageUrl', '');
                    }}
                  >
                    <AiFillCloseCircle size={14} color='var(--danger)' />
                  </button>
                </Row>
              ) : (
                <Row className=''>
                  <ImageUploadButton
                    fill={'outline'}
                    className={'w-full'}
                    onFileUploaded={(result) => {
                      handleImageChanged('overviewImageUrl', result.httpsUrl);
                    }}
                  />
                </Row>
              )}
            </Col>
          </Stretched>
        </Col>
      </Section>

      {/* BODY SECTION */}
      <Section gap={5}>
        <Col>
          <SectionTitle>3. Add Body Content to Event Pages</SectionTitle>
          <Text>
            This section controls the body copy or content for each stage of
            your event. You can customize each page and content will overwrite
            for each consecutive stage through event completion. This is the
            main content area where you can describe each phase of your event
            with a title and paragraph text.
          </Text>
        </Col>
        <Col>
          <div className='my-3'>
            <Row className='mb-3'>
              {contentBodyTabs.map((tab, index) => (
                <Button
                  className={classNames(
                    'btn-sm btn-tab',
                    tab?.value === bodyContentDisplay && 'active',
                  )}
                  onClick={() => setBodyContentDisplay(tab.value)}
                  key={index}
                >
                  {tab.label}
                </Button>
              ))}
            </Row>
            {contentBodyTabs.map((tab, index) => (
              <div
                key={index}
                className={classNames(
                  tab?.value !== bodyContentDisplay ? 'hidden' : 'block',
                )}
              >
                <Editor
                  required={true}
                  name={tab.value}
                  contentHtml={formState[tab.value]}
                  onChange={(e) => {
                    setFormState({ ...formState, [tab.value]: e });
                  }}
                />
              </div>
            ))}

            <div className='flex items-center h-7'>
              {!contentBodyTabs.every((tab) => formState[tab.value]) ? (
                <Text className='text-danger-500 text-sm text-right w-full'>
                  Please fill out all content body tabs.
                </Text>
              ) : null}
            </div>
          </div>
        </Col>
      </Section>
    </Stacked>
  );
};
