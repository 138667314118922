import { ConfettiMessage, CountdownTimer } from '@newComponents';
import { ProjectColors } from 'tokensoft-shared-types';

type EligibilityRequirementsMetProps = {
  isIdentityEvent: boolean;
  onFinished: () => void;
  eventStartsAt: Maybe<Date>;
  themeColors?: ProjectColors;
};

export const EligibilityRequirementsMet = ({
  isIdentityEvent,
  onFinished,
  eventStartsAt,
  themeColors,
}: EligibilityRequirementsMetProps) => {
  const startsInFuture =
    eventStartsAt !== null &&
    eventStartsAt !== undefined &&
    eventStartsAt > new Date();
  const message = isIdentityEvent
    ? 'Your identity has been verified.'
    : 'You have met all the requirements to participate in this event. Return to this page when the event starts to participate.';

  return (
    <ConfettiMessage
      message={message}
      onFinished={onFinished}
      themeColors={themeColors}
    >
      {startsInFuture && eventStartsAt && (
        <CountdownTimer date={eventStartsAt} />
      )}
    </ConfettiMessage>
  );
};
