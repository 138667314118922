import { useAccountApiClient } from '@apiClients';
import { useAccount, useAuth, useToast } from '@contexts';
import type { Account, AccountDetails, Address } from '@customTypes';
import { INVESTOR_TYPE } from '@enums';
import { useAsync } from '@hooks';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { localToUtcDateTime } from '@utils';
import { useEffect } from 'react';

export const useGetAccount = () => {
  const {
    isAuthenticated,
    user: { walletAddress },
  } = useAuth();
  const client = useAccountApiClient();
  const result = useQuery({
    enabled: isAuthenticated,
    queryKey: ['account', walletAddress],
    queryFn: () => client(`accounts/me`),
  });

  return isAuthenticated
    ? { ...result, account: result.data || null }
    : { isLoading: false, account: null, refetch: result.refetch };
};

export const useResendVerificationEmail = () => {
  const queryClient = useQueryClient();
  const client = useAccountApiClient();
  const {
    user: { authId },
  } = useAuth();
  return useMutation({
    mutationFn: (data: any) => {
      return client(`accounts/${authId}/email/verify/resend`, {
        method: 'post',
        data: {},
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['account'] }),
  });
};

export const useVerifyEmailToken = () => {
  const queryClient = useQueryClient();
  const {
    user: { authId },
  } = useAuth();
  const client = useAccountApiClient();
  return useMutation({
    mutationFn: (emailToken: string) => {
      return client(`accounts/email/verify`, {
        method: 'post',
        data: {
          authId,
          emailToken: emailToken,
        },
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['account'] }),
  });
};

export const useGetAccountByEmail = () => {
  const client = useAccountApiClient();
  return useMutation({
    mutationFn: (data: any) => {
      return client(
        `admin/projects/${data.projectId}/accounts/${data.authId}`,
        {
          method: 'get',
        },
      );
    },
  });
};

export const useCreateAccountKycTier = () => {
  const client = useAccountApiClient();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToast();

  return useMutation({
    mutationFn: (data: any) => {
      return client('accounts/kyc/tiers', {
        data,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['account'] });
    },
    onError: (error: Error) => {
      console.log(error);
      showErrorToast({ description: error.message });
    },
  });
};

export const useUpdateAccountKycTier = () => {
  const client = useAccountApiClient();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToast();

  return useMutation({
    mutationFn: ({ accountKycTierId, data }: any) => {
      return client(`accounts/kyc/tiers/${accountKycTierId}`, {
        method: 'put',
        data,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['account'] });
    },
    onError: (error: Error) => {
      console.log(error);
      showErrorToast({ description: error.message });
    },
  });
};

export const useGetMessageByToken = (messageToken) => {
  const { isAuthenticated } = useAuth();
  const { run, data, isPending } = useAsync();
  const client = useAccountApiClient();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!messageToken) {
      return;
    }

    run(client(`accounts/messages/${messageToken}`));
  }, [isAuthenticated, messageToken, run]);

  return !isAuthenticated
    ? { result: false, isPending }
    : { result: data, isPending };
};

export const useCreateMessage = () => {
  const client = useAccountApiClient();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToast();

  return useMutation({
    mutationFn: (data: any) => {
      return client('accounts/messages', {
        data,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['account'] });
    },
    onError: (error: Error) => {
      console.log(error);
      showErrorToast({ description: error.message });
    },
  });
};

export const useUpdateAccount = () => {
  const { account } = useAccount();
  const client = useAccountApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`accounts/${account.id}`, {
        method: 'put',
        data,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['account'] }),
  });
};

export const useGenerateAccessToken = () => {
  const client = useAccountApiClient();

  return useMutation({
    mutationFn: (
      levelName: string = 'basic-kyc-level',
    ): Promise<{ token: string }> => {
      const data = {
        levelName,
      };

      return client(`accounts/idv/token`, {
        method: 'post',
        data,
      });
    },
  });
};

export const useSetAccountDetails = (authId: number, accountId: string) => {
  const queryClient = useQueryClient();
  const client = useAccountApiClient();

  return useMutation({
    mutationFn: (accountDetails: AccountDetails) => {
      const data: any = {
        authId,
        firstName: accountDetails.firstName,
        lastName: accountDetails.lastName,
        dob: localToUtcDateTime(accountDetails.dob),
        phoneNumber: accountDetails.phoneNumber,
        email: accountDetails.email,
        emailVerified: accountDetails.emailVerified,
        timezone: accountDetails.timezone,
        title: accountDetails.title,
        investorType: accountDetails.investorType,
        addresses: [
          {
            addressType: INVESTOR_TYPE.MYSELF,
            flatNumber: accountDetails.flatNumber,
            buildingNumber: accountDetails.buildingNumber,
            streetLine1: accountDetails.streetLineOne,
            streetLine2: accountDetails.streetLineTwo,
            city: accountDetails.city,
            state: accountDetails.state,
            postcode: accountDetails.zipCode,
            country: accountDetails.country,
          },
        ],
      };

      // If entity, add the extra address for entities
      if (accountDetails.investorType === INVESTOR_TYPE.ENTITY) {
        const entityAddress = {
          addressType: INVESTOR_TYPE.ENTITY,
          flatNumber: accountDetails.entityFlatNumber,
          buildingNumber: accountDetails.entityBuildingNumber,
          streetLine1: accountDetails.entityStreetLineOne,
          streetLine2: accountDetails.entityStreetLineTwo,
          city: accountDetails.entityCity,
          state: accountDetails.entityState,
          postcode: accountDetails.entityZipCode,
          country: accountDetails.entityCountry,
        };
        data['entityName'] = accountDetails.entityName;
        data['dba'] = accountDetails.entityDba;
        if (accountDetails.entityFormationDate) {
          data['entityFormationDate'] = localToUtcDateTime(
            accountDetails.entityFormationDate,
          );
        }
        data['entityTaxId'] = accountDetails.entityTaxId;
        data.addresses = [...data.addresses, entityAddress];
      }

      return client(`accounts/${accountId}`, {
        method: 'put',
        data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['account'] });
    },
  });
};

export const initialAccountDetailsState = (account: Account) => {
  if (!account) {
    return null;
  }

  const individual: Address | undefined = account.addresses?.find(
    (address) => address.addressType === INVESTOR_TYPE.MYSELF,
  );
  const entity: Address | undefined = account.addresses?.find(
    (address) => address.addressType === INVESTOR_TYPE.ENTITY,
  );

  return {
    investorType: account.investorType,
    firstName: account.firstName,
    middleName: account.middleName,
    lastName: account.lastName,
    dob: account.dob,
    email: account.email,
    emailVerified: account.emailVerified,
    timezone: account.timezone,
    flatNumber: individual?.flatNumber,
    buildingNumber: individual?.buildingNumber,
    buildingName: individual?.buildingName,
    streetLineOne: individual?.streetLine1,
    streetLineTwo: individual?.streetLine2,
    state: individual?.state,
    city: individual?.city,
    zipCode: individual?.postcode,
    country: individual?.country,
    phoneNumber: account.phoneNumber, // Update this so that entity and individual have their own phone numbers
    title: entity?.title,
    entityTitle: entity?.title,
    entityName: account.entityName,
    entityCountry: entity?.country,
    entityFlatNumber: entity?.flatNumber,
    entityBuildingNumber: entity?.buildingNumber,
    entityBuildingName: entity?.buildingName,
    entityStreetLineOne: entity?.streetLine1,
    entityStreetLineTwo: entity?.streetLine2,
    entityCity: entity?.city,
    entityState: entity?.state,
    entityZipCode: entity?.postcode,
    entityDba: account.dba,
    entityFormationDate: account.entityFormationDate,
    entityTaxId: account.entityTaxId,
  };
};
