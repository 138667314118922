import { DOCUMENT_CATEGORY, DOCUMENT_SHOW_TO_TYPE, EVENT_TYPE } from '@enums';
import { EventDocument, EventUserDocumentStatus } from 'tokensoft-shared-types';

export const isUSOnly = (regions: string[]): boolean => {
  return regions?.length === 1 && regions[0] === 'US';
};

export const hasUSCountry = (regions: string[]): boolean => {
  return regions?.includes('US');
};

export const showDocumentTo = (regions: string[]): DOCUMENT_SHOW_TO_TYPE => {
  if (isUSOnly(regions)) {
    return DOCUMENT_SHOW_TO_TYPE.US_ONLY;
  }

  if (!hasUSCountry(regions)) {
    return DOCUMENT_SHOW_TO_TYPE.OUTSIDE_US;
  }

  return DOCUMENT_SHOW_TO_TYPE.ALL_ELIGIBLE_PERSONS;
};

export const getDocumentCategoryForEventType = (eventType: EVENT_TYPE) => {
  switch (eventType) {
    case EVENT_TYPE.DISTRIBUTE:
      return DOCUMENT_CATEGORY.CLAIM_AGREEMENTS;
    case EVENT_TYPE.RAISE:
    case EVENT_TYPE.SALE:
      return DOCUMENT_CATEGORY.PURCHASE_AGREEMENTS;
    case EVENT_TYPE.IDENTITY:
      return DOCUMENT_CATEGORY.COMPLIANCE_DOCUMENTS;
    default:
      return null;
  }
};

export const formatCreateEventDocumentsPayload = (
  documentCategory,
  documents,
  allowedRegions,
) => {
  return documents.map((doc) => {
    let document = {
      name: doc.documentTitle,
      documentCategory,
      contentType: doc.uploadedFile?.contentType,
      uri: doc.uploadedFile?.uri,
    };

    if (doc.showDocumentTo) {
      // Default to all regions are allowed
      let regionRestriction = allowedRegions;
      if (doc.showDocumentTo === DOCUMENT_SHOW_TO_TYPE.OUTSIDE_US) {
        // All regions are allowed but US
        regionRestriction = allowedRegions?.filter((region) => region !== 'US');
      } else if (doc.showDocumentTo === DOCUMENT_SHOW_TO_TYPE.US_ONLY) {
        // US Only
        regionRestriction = ['US'];
      }

      let documentSignaturePayload = {
        regionRestriction,
        entityName: doc?.companyName,
        signerName: doc?.signerName,
        signerTitle: doc?.signerTitle,
        signerSectionLabel: doc?.company,
        counterpartySectionLabel: doc?.recipient,
        addendumPageLabel: doc?.addendum,
      };

      document = Object.assign(document, documentSignaturePayload, {});
    }

    return document;
  });
};

export const eventDocumentsToEditEventDocuments = (eventDocuments) => {
  if (!eventDocuments) {
    return [];
  }

  return eventDocuments.map((eventDocument) => {
    const fileName =
      eventDocument.documentVersion?.uri?.split('_').pop() || '-';
    const uploadedFile = {
      contentType: eventDocument.documentVersion.contentType,
      filename: fileName,
      uri: eventDocument.documentVersion.uri,
    };

    let _eventDocument = {
      documentTitle: eventDocument.documentVersion?.document?.name,
      documentCategory:
        eventDocument.documentVersion?.document?.documentCategory,
      contentType: eventDocument.documentVersion?.contentType,
      uri: eventDocument.documentVersion?.uri,
      regionRestriction: eventDocument.regionRestriction,
      showDocumentTo: showDocumentTo(eventDocument.regionRestriction),
      signatureRequired: eventDocument.requiresSignature,
      uploadedFile,
    };

    const generatedDocumentConfig = eventDocument.generatedDocumentConfig
      ? JSON.parse(eventDocument.generatedDocumentConfig)
      : null;
    const fields = generatedDocumentConfig?.fields;
    if (fields) {
      _eventDocument = Object.assign(_eventDocument, {
        companyName: fields['entity_name'],
        signerName: fields['signer_name'],
        signerTitle: fields['signer_title'],
        company: fields['signer_section_label'],
        recipient: fields['counterparty_section_label'],
        addendum: fields['addendum_page_label'],
      });
    }
    return _eventDocument;
  });
};

export const userHasSignedOrAcceptedEventDocument = (
  eventDocument: EventDocument,
  userDocumentStatus: EventUserDocumentStatus,
): boolean => {
  if (eventDocument.requiresSignature) {
    return userDocumentStatus.signedAt !== null;
  }

  return userDocumentStatus.acceptedAt !== null;
};

export const userHasSignedOrReviewedAllDocuments = (
  eventDocuments: EventDocument[],
  eventUserDocumentsStatus: EventUserDocumentStatus[],
) => {
  return eventDocuments.every((doc) => {
    // If user doesn't have a document status for the event document,
    // they haven't signed or accepted it
    const userDoc = eventUserDocumentsStatus.find(
      (userDocumentStatus) =>
        userDocumentStatus.eventDocumentVersionId === doc.id,
    );
    if (userDoc === undefined) {
      return false;
    }

    return userHasSignedOrAcceptedEventDocument(doc, userDoc);
  });
};